
.exhibition-tab {
    height: 56px;
    display: flex;
    align-items: center;
    li {
        width: 100%;
        height: 100%;
        border-right: 1px solid var(--v-grey-lighten4);
        &:last-child {
            border-right: none;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-weight: 500;
            color: var(--v-grey-lighten1);
        }
        .router-link-active {
            background-color: var(--v-primary-base);
            color: #fff;
        }
    }
}
