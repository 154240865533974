
.board-list__txt.subject::before {
    display: none;
}
// .board-list {
//     border-top: 2px solid var(--v-grey-darken4);
//     &__tit {
//         padding: 12px 0;
//         font-weight: 500;
//     }
//     &__txt {
//         padding: 18px 10px;
//     }
//     tbody {
//         border-top: 1px solid var(--v-grey-lighten4);
//         tr {
//             border-bottom: 1px solid var(--v-grey-lighten4);
//         }
//     }
// }
// ::v-deep {
//     .v-select__selections {
//         padding: 0 !important;
//     }
//     .select-box input::placeholder {
//         color: var(--v-grey-darken4);
//     }
// }
// @media (min-width: 768px) {
//     .board-list {
//         &__tit {
//             padding: 24px 0;
//         }
//         &__txt {
//             padding: 36px 20px;
//         }
//     }
// }
// @media (min-width: 1024px) {
// }
