
.collection-detail {
    &__head {
        display: inline-block;
        width: 40px;
    }
}
@media (min-width: 768px) {
    .collection-detail__head {
        width: 60px;
    }
}
@media (min-width: 1024px) {
    .collection-detail__head {
        width: 72px;
    }
}
.v-divider {
    border-color: var(--v-grey-lighten4);
}
