
.donation-step {
    flex-direction: column;
    .icon-svg {
        transform: rotate(-90deg);
    }
}
@media (min-width: 768px) {
    .donation-step {
        flex-direction: initial;
        .icon-svg {
            transform: rotate(180deg);
        }
    }
}
