
.image-board-card {
    border: 1px solid var(--v-grey-lighten3);
    .tit-box {
        padding: 0 10px;
        height: 40px;
        overflow: visible;
    }
}
@media (min-width: 768px) {
    .image-board-card .tit-box {
        height: 54px;
    }
}
