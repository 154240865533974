
.v-tab--filled {
    background-color: transparent !important;
    border-top: 1px solid #ECECEC;
    border-left: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
    &:last-child {
        border-right: 1px solid #ECECEC;
    }
    &.v-tab{
        &--active {
            color: #fff !important;
            background-color: var(--v-primary-base) !important;
        }
    }
}
